// src/components/Sell.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/Sell.css';

const Sell: React.FC = () => {

  const navigate = useNavigate();

  const [type, setType] = useState<string>('');
  const [brand, setBrand] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [trim, setTrim] = useState<string>('');
  const [year, setYear] = useState<number | null>(null);
  const [mileage, setMileage] = useState<string>('');
  const [vin, setVin] = useState<string>('');
  const [service, setService] = useState<string>('');
  const [damage, setDamage] = useState<string>('');
  const [reserve, setReserve] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  // Personal Details
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true); // Start loading

    const captchaToken = (document.querySelector('.cf-turnstile input[name="cf-turnstile-response"]') as HTMLInputElement)?.value;

    const relevantTypes = ['Class A', 'Class B', 'Class C'];
    const formData = {
      type: type || null,
      brand: brand || null,
      model: model || null,
      trim: trim || null,
      year: year || null,
      mileage: relevantTypes.includes(type) ? (mileage || null) : null,
      vin: vin || null,
      service: service || null,
      damage: damage || null,
      reserve: reserve || null,
      notes: notes || null,
      country: country || null,
      city: city || null,
      name: name || null,
      email: email || null,
      phone: phone || null,
      captchaToken,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/sell`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Form Data Submitted Successfully:', result.data);
        navigate('/success');
      } else {
        console.error('Error submitting form:', result.message);
        // Extract detailed error messages if available
        const errorMessages = result.errors
          ? result.errors.map((err: any) => err.msg).join('\n')
          : result.message || 'Unknown error';
        alert(`Error submitting form:\n${errorMessages}`);
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error occurred while submitting the form. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="sell-page">
      <h2>Just One Step</h2>
      <p className="sub-title">Easy & quick to fill out.</p>
      <form className="sell-form" onSubmit={handleSubmit}>
        {/* RV Details Section */}
        <fieldset disabled={isLoading}>
        <div className="section">
          <h3>RV Details</h3>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select
              id="type"
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="" disabled>
                Select RV Type
              </option>
              <option value="Class A">Class A</option>
              <option value="Class B">Class B</option>
              <option value="Class C">Class C</option>
              <option value="Travel Trailer">Travel Trailer</option>
              <option value="Fifth Wheel">Fifth Wheel</option>
              <option value="Toy Hauler">Toy Hauler</option>
              <option value="Pop Up Trailer">Pop Up Trailer</option>
              <option value="Truck Camper">Truck Camper</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="brand">Brand</label>
            <input
              type="text"
              id="brand"
              name="brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              placeholder="Enter RV brand"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="model">Model</label>
            <input
              type="text"
              id="model"
              name="model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              placeholder="Enter RV model"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="trim">Trim</label>
            <input
              type="text"
              id="trim"
              name="trim"
              value={trim}
              onChange={(e) => setTrim(e.target.value)}
              placeholder="Enter RV trim level"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="year">Year</label>
            <input
              type="number"
              id="year"
              name="year"
              value={year || ''}
              onChange={(e) => setYear(e.target.value ? parseInt(e.target.value) : null)}
              placeholder="Enter RV year"
              required
            />
          </div>

          {['Class A', 'Class B', 'Class C'].includes(type) && (
            <div className="form-group">
              <label htmlFor="mileage">Mileage</label>
              <input
                type="text"
                id="mileage"
                name="mileage"
                value={mileage}
                onChange={(e) => setMileage(e.target.value)}
                placeholder="Enter RV mileage"
                required
              />
            </div>
          )}

          <div className="form-group">
            <label htmlFor="vin">VIN</label>
            <input
              type="text"
              id="vin"
              name="vin"
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              placeholder="Enter VIN/chassis number"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="service">Service History</label>
            <input
              type="text"
              id="service"
              name="service"
              value={service}
              onChange={(e) => setService(e.target.value)}
              placeholder="Enter any available service records"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="damage">Damages</label>
            <input
              type="text"
              id="damage"
              name="damage"
              value={damage}
              onChange={(e) => setDamage(e.target.value)}
              placeholder="Describe any scratches, dents, etc."
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="reserve">Reserve Price</label>
            <input
              type="text"
              id="reserve"
              name="reserve"
              value={reserve}
              onChange={(e) => setReserve(e.target.value)}
              placeholder="Enter your reserve price"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any additional notes"
              required
            ></textarea>
          </div>
        </div>

        {/* Personal Details Section */}
        <div className="section">
          <h3>My Details</h3>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter your country"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City/Town</label>
            <input
              type="text"
              id="city"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter your city"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
              required
            />
          </div>
        </div>

        {/* Added the CAPTCHA widget */}
        <div className="form-group">
            <div className="cf-captcha">
                <div
                    className="cf-turnstile"
                    data-sitekey="0x4AAAAAAAy58fTFkTWs5KIN"
                    data-theme="light"
                ></div>
            </div>
        </div>
        </fieldset>

        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? (
            <>
              Submitting...
              <span className="spinner"></span>
            </>
          ) : (
            'Submit'
          )}
        </button>
      </form>
      <p className="sub-title">Once done, sit back, relax and watch the offers come to you!</p>
    </div>
  );
};

export default Sell;