// PrivacyPolicy.tsx
import React from 'react';
import './style/PrivacyPolicy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: November 3rd, 2024</p>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to rvbig. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices regarding your personal information, please contact us.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>
          We collect personal information that you voluntarily provide to us when registering on the Website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Website or otherwise contacting us. The personal information we collect may include:
        </p>
        <ul>
          <li>Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Other relevant contact details</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect or receive:
        </p>
        <ul>
          <li>To facilitate account creation and logon process.</li>
          <li>To send you marketing and promotional communications.</li>
          <li>To send you quotes from RV dealers based on your requests.</li>
          <li>To manage user accounts and provide customer support.</li>
          <li>To facilitate rvbig's regular course of business.</li>
          <li>To comply with legal obligations.</li>
        </ul>
      </section>

      <section>
        <h2>Sharing Your Information</h2>
        <p>
          We may share your information with third-party service providers who perform services for us or on our behalf, such as data analysis, email delivery, hosting services, customer service, marketing assistance, and other functions.
        </p>
        <p>
          Additionally, we may share your information with RV dealers to provide you with quotes and offers based on your requests.
        </p>
      </section>

      <section>
        <h2>Data Security</h2>
        <p>
          We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable.
        </p>
      </section>

      <section>
        <h2>Your Privacy Rights</h2>
        <p>
          Depending on your location, you may have the following rights regarding your personal information:
        </p>
        <ul>
          <li>The right to access – You have the right to request copies of your personal data.</li>
          <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
          <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
          <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
          <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
          <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <p>
          To exercise any of these rights, please contact us using the contact information provided below.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us using the 'Contact Us' button in the footer of the website:
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;