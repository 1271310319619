import React, { useState } from 'react';
import './style/Carousel.css';

interface RVCarouselProps {
  images: Array<{
    id: number;
    rv_id: number;
    location: string;
    sort: number;
  }>;
}

const RVCarousel: React.FC<RVCarouselProps> = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  if (images.length === 0) {
    return <div>No images available.</div>;
  }

  const currentImage = images[currentIndex]?.location;
  const publicUrl = process.env.REACT_APP_PUBLIC_URL || '';

  return (
    <div className="rv-carousel">
      <button onClick={prevImage}>&lt;</button>
      <img
        src={`${publicUrl}${currentImage}`}
        alt={`RV ${currentIndex + 1}`}
      />
      <button onClick={nextImage}>&gt;</button>
    </div>
  );
};

export default RVCarousel;