// src/components/Success.tsx

import React from 'react';
import './style/Success.css';

const Success: React.FC = () => {

  return (
    <div className="success-page">
      <h1>Great news!</h1>
      <h3>Your request has been submitted successfully.</h3>
      <p>You will receive offers from dealers shortly.</p>
    </div>
  );
};

export default Success;