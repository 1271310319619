// src/components/Header.tsx

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style/Header.css';
import Menu from './Menu.tsx'; // Import the Menu component
import './style/Menu.css'; // Ensure Menu styles are applied

const Header: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility

  useEffect(() => {
    const user = 'hello';
    const domain = 'rvbig.com';
    setEmail(`${user}@${domain}`);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto'; // Re-enable scrolling
    }
  }, [isMenuOpen]);

  const handleEmailClick = () => {
    window.location.href = `mailto:${email}`;
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="Header">
        <Link to="/" className="logo-link">
          <img src="/logo72x64colour.png" alt="RV Big Logo" className="App-logo" />
          <h1>rvbig</h1>
        </Link>
        <div className="header-right">
          {/* Hamburger Menu Always Visible */}
          <button className="menu-button" onClick={toggleMenu} aria-label="Open Menu">
            <img src="/icons/menu.png" alt="Menu" className="menu-icon" />
          </button>
        </div>
      </header>
      {/* Overlay */}
      {isMenuOpen && <div className={`overlay ${isMenuOpen ? 'open' : ''}`} onClick={closeMenu}></div>}
      {/* Sidebar Menu */}
      <Menu isOpen={isMenuOpen} toggleMenu={toggleMenu} handleEmailClick={handleEmailClick} />
    </>
  );
};

export default Header;