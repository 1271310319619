// src/components/About.tsx

import React from 'react';
import './style/About.css';

const About: React.FC = () => {
  return (
    <div className="about">
      <h2 className="about-title">About Us</h2>
      <p className="about-text">
        At <strong>rvbig</strong>, we are passionate about <em>RVing</em>! <br /><br />
        Our <strong>mission</strong> is to make RV ownership easier and enjoyable for everyone. <br /><br />
        So you can spend less time on admin, and more time exploring and having fun with your RV!<br /><br />
        ---------- <br /><br />
        We are a small and <strong>friendly</strong> team, with broad skills and dedicated to satisfying users. <br /><br />
        Please reach out to us if you have any questions or feedback, we would love to chat! <br /><br />
      </p>
    </div>
  );
};

export default About;