// Card.tsx

import React, { useState } from 'react';
import './style/Card.css';
import { RV } from './data/types';
import Carousel from './Carousel.tsx';
import Modal from './Modal.tsx';
import QuoteForm from './QuoteForm.tsx';

interface CardProps {
  rv: RV;
}

const Card: React.FC<CardProps> = ({ rv }) => {
  const [showModal, setShowModal] = useState(false);
  const [shareMessage, setShareMessage] = useState('');

  // Format the price
  const formattedPrice = !isNaN(rv.price)
    ? new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: rv.ccy || 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(rv.price)
    : 'N/A';

  // Construct the brand logo image path
  const brandFileName = `${rv.brand.replace(/\s+/g, '')}logo.png`;
  const brandLogoSrc = `/logos/${brandFileName}`;

  // Handle Share Button Click
  const handleShare = () => {
    const shareUrl = `${window.location.origin}/rv/${rv.id}`;
    navigator.clipboard.writeText(shareUrl).then(
      () => {
        setShareMessage('Link copied to clipboard!');
        setTimeout(() => setShareMessage(''), 3000);
      },
      (err) => {
        console.error('Could not copy text:', err);
      }
    );
  };

  return (
    <div className="rv-card">
      <div className="logo-container">
        <div className="brand-logo-container">
          <img src={brandLogoSrc} alt={rv.brand} className="brand-logo" />
        </div>
        {/* Replace Chassis Logo with Share Button */}
        <button className="share-button" onClick={handleShare} aria-label="Share RV">
          <img src="/icons/ios_share.svg" alt="Share Icon" className="share-icon" />
        </button>
      </div>

      {/* Optional Message After Sharing */}
      {shareMessage && <div className="share-message">{shareMessage}</div>}

      <div className="series-name-wrapper">
        {rv.series} <strong>{rv.name}</strong>
      </div>
      <div className="carousel-wrapper">
        <Carousel images={rv.images ?? []} />
      </div>

      <div className="rv-price-wrapper">
        From <strong>{formattedPrice}</strong>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <QuoteForm rv={`${rv.brand} ${rv.series} ${rv.name}`} onClose={() => setShowModal(false)} />
      </Modal>
      <div className='quote-button'>
        <button onClick={() => setShowModal(true)}>Compare Dealer Prices</button>
      </div>
      <hr />
      <p>
        L: <strong>{rv.long}'</strong>; H: <strong>{rv.high}'</strong>; W: <strong>{rv.wide}'</strong>
      </p>
      <hr />
      <p>
        Sleeps: <strong>{rv.sleep}</strong>; Seats: <strong>{rv.seat}</strong>
      </p>
      <hr />
      <p>
        CCC: <strong>{rv.load}lb</strong>; GVW: <strong>{rv.gvw}lb</strong>
      </p>
    </div>
  );
};

export default Card;