// src/components/Buy.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/Buy.css';

const Buy: React.FC = () => {

  const navigate = useNavigate();

  const [know, setKnow] = useState<boolean | null>(null);
  const [type, setType] = useState<string>('');
  const [brand, setBrand] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [dimensions, setDimensions] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [budget, setBudget] = useState<string>('');
  const [extras, setExtras] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (know === null) {
      alert('Please indicate whether you know which RV you want to buy.');
      return;
    }

    setIsLoading(true); // Start loading

    const captchaToken = (document.querySelector('.cf-turnstile input[name="cf-turnstile-response"]') as HTMLInputElement)?.value;

    const formData: any = {
      know: know,
      type: type || undefined,
      brand: know ? (brand || undefined) : undefined,
      model: know ? (model || undefined) : undefined,
      dimensions: know === false ? (dimensions || undefined) : undefined,
      weight: know === false ? (weight || undefined) : undefined,
      budget: know === false ? (budget || undefined) : undefined,
      extras: extras || undefined,
      notes: notes || undefined,
      country: country || undefined,
      city: city || undefined,
      name: name || undefined,
      email: email || undefined,
      phone: phone || undefined,
      captchaToken,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/buy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('Result object:', result);

      if (response.ok) {
        console.log('Form Data Submitted Successfully:', result.data);
        navigate('/success');
      } else {
        console.error('Error submitting form:', result.message);
        // Extract detailed error messages if available
        const errorMessages = result.errors
          ? result.errors.map((err: any) => err.msg).join('\n')
          : result.message || 'Unknown error';
        alert(`Error submitting form:\n${errorMessages}`);
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error occurred while submitting the form. Please try again.');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="buy-page">
      <h2>Just One Step</h2>
      <p className="sub-title">Easy & quick to fill out.</p>
      <form className="buy-form" onSubmit={handleSubmit}>
        {/* RV Details Section */}
      <fieldset disabled={isLoading}>
        <div className="section">
          <h3>RV Details</h3>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select
              id="type"
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="" disabled>
                Select RV Type
              </option>
              <option value="Class A">Class A</option>
              <option value="Class B">Class B</option>
              <option value="Class C">Class C</option>
              <option value="Travel Trailer">Travel Trailer</option>
              <option value="Fifth Wheel">Fifth Wheel</option>
              <option value="Toy Hauler">Toy Hauler</option>
              <option value="Pop Up Trailer">Pop Up Trailer</option>
              <option value="Truck Camper">Truck Camper</option>
            </select>
          </div>

          <div className="form-group">
            <label>Do you know which RV you want to buy?</label>
            <div className="button-group">
              <button
                type="button"
                className={`select-button ${know === true ? 'active' : ''}`}
                onClick={() => setKnow(true)}
                aria-pressed={know === true}
              >
                Yes
              </button>
              <button
                type="button"
                className={`select-button ${know === false ? 'active' : ''}`}
                onClick={() => setKnow(false)}
                aria-pressed={know === false}
              >
                No
              </button>
            </div>
          </div>

          {know === true && (
            <>
              <div className="form-group">
                <label htmlFor="brand">Brand</label>
                <input
                  type="text"
                  id="brand"
                  name="brand"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  placeholder="Enter RV brand"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="model">Model</label>
                <input
                  type="text"
                  id="model"
                  name="model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  placeholder="Enter RV model"
                  required
                />
              </div>
            </>
          )}

          {know === false && (
            <>
              <div className="form-group">
                <label htmlFor="dimensions">Dimensions</label>
                <input
                  type="text"
                  id="dimensions"
                  name="dimensions"
                  value={dimensions}
                  onChange={(e) => setDimensions(e.target.value)}
                  placeholder="Enter any min/max dimensions"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="weight">Weight</label>
                <input
                  type="text"
                  id="weight"
                  name="weight"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  placeholder="Enter any min/max weights"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="budget">Budget</label>
                <input
                  type="text"
                  id="budget"
                  name="budget"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  placeholder="Enter your budget"
                  required
                />
              </div>
            </>
          )}

          <div className="form-group">
            <label htmlFor="extras">Extras</label>
            <input
              type="text"
              id="extras"
              name="extras"
              value={extras}
              onChange={(e) => setExtras(e.target.value)}
              placeholder="Enter any required accessories/extras"
            />
          </div>

          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter any additional notes"
            ></textarea>
          </div>
        </div>

        {/* Personal Details Section */}
        <div className="section">
          <h3>My Details</h3>
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Enter your country"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City/Town</label>
            <input
              type="text"
              id="city"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Enter your city"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your phone number"
              required
            />
          </div>
          {/* Turnstile Widget */}
          <div className="form-group">
            <div className="cf-captcha">
              <div
                className="cf-turnstile"
                data-sitekey="0x4AAAAAAAy58fTFkTWs5KIN"
                data-theme="light"
              ></div>
            </div>
          </div>
        </div>
        </fieldset>

        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? (
            <>
              Submitting...
              <span className="spinner"></span>
            </>
          ) : (
            'Submit'
          )}
        </button>
      </form>
      <p className="sub-title">Once done, sit back, relax and watch the offers come to you!</p>
    </div>
  );
};

export default Buy;