// Filter.tsx

import React, { useState } from 'react';
import './style/Filter.css';

interface FilterProps {
  minPrice: number | null;
  maxPrice: number | null;
  minLength: number | null;
  maxLength: number | null;
  minHeight: number | null;
  maxHeight: number | null;
  minWidth: number | null;
  maxWidth: number | null;
  minGVW: number | null;
  maxGVW: number | null;
  minPayload: number | null;
  maxPayload: number | null;
  selectedBrands: string[];
  selectedSeries: string[];
  setMinPrice: (value: number | null) => void;
  setMaxPrice: (value: number | null) => void;
  setMinLength: (value: number | null) => void;
  setMaxLength: (value: number | null) => void;
  setMinHeight: (value: number | null) => void;
  setMaxHeight: (value: number | null) => void;
  setMinWidth: (value: number | null) => void;
  setMaxWidth: (value: number | null) => void;
  setMinGVW: (value: number | null) => void;
  setMaxGVW: (value: number | null) => void;
  setMinPayload: (value: number | null) => void;
  setMaxPayload: (value: number | null) => void;
  setSelectedBrands: (value: string[]) => void;
  setSelectedSeries: (value: string[]) => void;
  availableBrands: string[];
  availableSeries: string[];
}

const Filter: React.FC<FilterProps> = ({
  minPrice,
  maxPrice,
  minLength,
  maxLength,
  minHeight,
  maxHeight,
  minWidth,
  maxWidth,
  minGVW,
  maxGVW,
  minPayload,
  maxPayload,
  selectedBrands,
  selectedSeries,
  setMinPrice,
  setMaxPrice,
  setMinLength,
  setMaxLength,
  setMinHeight,
  setMaxHeight,
  setMinWidth,
  setMaxWidth,
  setMinGVW,
  setMaxGVW,
  setMinPayload,
  setMaxPayload,
  setSelectedBrands,
  setSelectedSeries,
  availableBrands,
  availableSeries,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCheckboxChange = (
    setter: (value: string[]) => void,
    value: string
  ) => {
    setter((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleResetFilters = () => {
    setMinPrice(null);
    setMaxPrice(null);
    setMinLength(null);
    setMaxLength(null);
    setMinHeight(null);
    setMaxHeight(null);
    setMinWidth(null);
    setMaxWidth(null);
    setMinGVW(null);
    setMaxGVW(null);
    setMinPayload(null);
    setMaxPayload(null);
    setSelectedBrands([]);
    setSelectedSeries([]);
  };

  return (
    <>
      {/* Filter Button */}
      <button className="filter-button" onClick={() => setShowModal(true)}>
        <img src="/icons/tune.svg" alt="Filter Icon" className="filter-icon" />
        <span>Filters</span>
      </button>

      {/* Modal Overlay */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div
            className="filters-modal"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="filters-title"
          >
            {/* Sticky Header */}
            <div className="modal-header">
              <h2>Filters</h2>
              <button
                className="close-button"
                onClick={() => setShowModal(false)}
                aria-label="Close Filters"
              >
                &times;
              </button>
            </div>

            {/* Scrollable Content */}
            <div className="modal-content">
              {/* Price Filter */}
              <div className="filter-number">
                <h4>Price</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min Price"
                    value={minPrice !== null ? minPrice : ''}
                    onChange={(e) => setMinPrice(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max Price"
                    value={maxPrice !== null ? maxPrice : ''}
                    onChange={(e) => setMaxPrice(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* Length Filter */}
              <div className="filter-number">
                <h4>Length</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min Length"
                    value={minLength !== null ? minLength : ''}
                    onChange={(e) => setMinLength(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max Length"
                    value={maxLength !== null ? maxLength : ''}
                    onChange={(e) => setMaxLength(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* Height Filter */}
              <div className="filter-number">
                <h4>Height</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min Height"
                    value={minHeight !== null ? minHeight : ''}
                    onChange={(e) => setMinHeight(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max Height"
                    value={maxHeight !== null ? maxHeight : ''}
                    onChange={(e) => setMaxHeight(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* Width Filter */}
              <div className="filter-number">
                <h4>Width</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min Width"
                    value={minWidth !== null ? minWidth : ''}
                    onChange={(e) => setMinWidth(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max Width"
                    value={maxWidth !== null ? maxWidth : ''}
                    onChange={(e) => setMaxWidth(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* GVW Filter */}
              <div className="filter-number">
                <h4>GVW</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min GVW"
                    value={minGVW !== null ? minGVW : ''}
                    onChange={(e) => setMinGVW(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max GVW"
                    value={maxGVW !== null ? maxGVW : ''}
                    onChange={(e) => setMaxGVW(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* Payload Filter */}
              <div className="filter-number">
                <h4>Payload</h4>
                <div className="filter-inputs">
                  <input
                    type="number"
                    placeholder="Min Payload"
                    value={minPayload !== null ? minPayload : ''}
                    onChange={(e) => setMinPayload(Number(e.target.value) || null)}
                  />
                  <input
                    type="number"
                    placeholder="Max Payload"
                    value={maxPayload !== null ? maxPayload : ''}
                    onChange={(e) => setMaxPayload(Number(e.target.value) || null)}
                  />
                </div>
              </div>

              {/* Brand Filter */}
              <div className="filter-checkbox">
                <h4>Brand</h4>
                <div className="checkbox-options">
                  {availableBrands.map((brand) => (
                    <label key={brand} className="checkbox-label">
                      <input
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxChange(setSelectedBrands, brand)
                        }
                        checked={selectedBrands.includes(brand)}
                      />
                      {brand}
                    </label>
                  ))}
                </div>
              </div>

              {/* Series Filter */}
              <div className="filter-checkbox">
                <h4>Series</h4>
                <div className="checkbox-options">
                  {availableSeries.map((series) => (
                    <label key={series} className="checkbox-label">
                      <input
                        type="checkbox"
                        onChange={() =>
                          handleCheckboxChange(setSelectedSeries, series)
                        }
                        checked={selectedSeries.includes(series)}
                      />
                      {series}
                    </label>
                  ))}
                </div>
              </div>
            </div>

            {/* Sticky Footer */}
            <div className="modal-footer">
              <button onClick={handleResetFilters} className="reset-button">
                Clear All
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="apply-button"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;