// QuoteForm.tsx

import React, { useState } from 'react';
import Select from 'react-select';
import { countryCodes, countries } from './data/countryCodes.ts';
import './style/QuoteForm.css';

interface QuoteFormProps {
  rv?: string;
  onClose: () => void;
}

const QuoteForm: React.FC<QuoteFormProps> = ({ rv, onClose }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState<{ value: string; label: string; flag: string } | null>(null);
  const [city, setCity] = useState('');
  const [country, setCountry] = useState<{ value: string; label: string; flag: string } | null>(null);
  const [comments, setComments] = useState('');

  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone: string) => {
    const re = /^[0-9-]{1,20}$/;
    return re.test(phone);
  };

  const validateCity = (city: string) => {
    const re = /^[a-zA-Z0-9\s-]{1,50}$/;
    return re.test(city);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (!city || !validateCity(city)) {
      alert('Please enter a valid city name');
      return;
    }
    if (!country) {
      alert('Country is required');
      return;
    }
    if (phone && !validatePhone(phone)) {
      alert('Please enter a valid phone number');
      return;
    }
    if (!rv) {
      if (!brand.trim()) {
        alert('Please enter the RV Brand');
        return;
      }
      if (!model.trim()) {
        alert('Please enter the RV Model');
        return;
      }
    }
    try {
      const combinedRV = !rv ? `${brand.trim()} ${model.trim()}` : rv;
      const payload: any = {
        rv: combinedRV,
        email,
        phone: phone ? `${countryCode?.value}_${phone}` : null,
        city,
        country: country?.label,
        comments,
      };
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/quotes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        alert('Quote request submitted successfully. We will be in touch!');
      } else {
        alert('Failed to submit quote request. Please try again or contact us directly.');
      }
    } catch (error) {
      console.error('Error submitting quote request:', error);
      alert('An error occurred while submitting your request. Please try again or contact us directly.');
    }
    onClose();
  };

  return (
    <form className="quote-form" onSubmit={handleSubmit}>
      {rv ? (
        <h2>
          Compare offers from dealers for the <u>{rv}</u>:
        </h2>
      ) : (
        <h2>Request a Quote for any specific RV:</h2>
      )}

      {!rv && (
        <>
          <div className="quote-form-levels">
            <label htmlFor="rv-brand">RV Brand:</label>
            <input
              id="rv-brand"
              type="text"
              placeholder="Enter RV Brand"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              required
            />
          </div>
          <div className="quote-form-levels">
            <label htmlFor="rv-model">RV Model:</label>
            <input
              id="rv-model"
              type="text"
              placeholder="Enter RV Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              required
            />
          </div>
        </>
      )}

      <div className="quote-form-levels">
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          placeholder="example@mail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>

      <div className="react-select-wrapper quote-form-levels">
        <label htmlFor="phone-country">Phone Country:</label>
        <Select
          id="phone-country"
          className="react-select-container"
          classNamePrefix="react-select"
          options={countryCodes}
          value={countryCode}
          onChange={(selectedOption) => setCountryCode(selectedOption as any)}
          placeholder="Country Code"
          getOptionLabel={(option) => `${option.flag} ${option.label}`}
          getOptionValue={(option) => option.value}
          isClearable
          required
        />
      </div>

      <div className="react-select-wrapper quote-form-levels">
        <label htmlFor="phone-number">Phone Number:</label>
        <input
          id="phone-number"
          type="text"
          placeholder="012 3456 789"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          maxLength={15}
        />
      </div>

      <div className="quote-form-levels">
        <label htmlFor="city">City/Town:</label>
        <input
          id="city"
          type="text"
          placeholder="City Name"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          maxLength={50}
          required
        />
      </div>

      <div className="react-select-wrapper quote-form-levels">
        <label htmlFor="country">Country:</label>
        <Select
          id="country"
          className="react-select-container"
          classNamePrefix="react-select"
          options={countries}
          value={country}
          onChange={(selectedOption) => setCountry(selectedOption as any)}
          placeholder="Select Country"
          getOptionLabel={(option) => `${option.flag} ${option.label}`}
          getOptionValue={(option) => option.value}
          isClearable
        />
      </div>

      <div className="quote-form-levels comments-field">
        <label htmlFor="comments">Comments/Additional Requirements (Optional):</label>
        <textarea
          id="comments"
          placeholder="Any required extras (Solar Panel, Air Conditioning, Awning, etc) or comments to share with dealers."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          rows={3}
          maxLength={500}
        />
      </div>

      <button type="submit">Submit</button>
      <p className="disclaimer">
        By submitting this form, you agree rvbig may contact you with RV quotes and offers.
      </p>
    </form>
  );
}

export default QuoteForm;