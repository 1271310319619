// Home.tsx
import React from 'react';
import MainBanner from './MainBanner.tsx';
import HowItWorks from './HowItWorks.tsx';
import FAQ from './FAQ.tsx';
import Recents from './Recents.tsx';

const Home: React.FC = () => {
  return (
    <div>
    <div><MainBanner /></div>
    <div><Recents /></div>
    <div><HowItWorks /></div>
    <div><FAQ /></div>
    </div>
  );
};

export default Home;