// WildCard.tsx

import React, { useState } from 'react';
import './style/WildCard.css';
import Modal from './Modal.tsx';
import QuoteForm from './QuoteForm.tsx';

const WildCard: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="wildcard-card">
      <div className="wildcard-content">
        <p>Start a reverse-auction for <strong><u>any RV brand & model!</u></strong></p>
        <button onClick={() => setShowModal(true)}>Request Quotes Now!</button>
        <p>RV dealers bid for your quote, so you can compare and pick from competing offers!</p>
      </div>
      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <QuoteForm onClose={() => setShowModal(false)} />
      </Modal>
    </div>
  );
};

export default WildCard;