// ShareCard.tsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from './Card.tsx';
import { RV } from './data/types';
import './style/ShareCard.css';

const ShareCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [rv, setRV] = useState<RV | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchRV = async () => {
      if (!id || isNaN(parseInt(id, 10))) {
        setError('Invalid RV ID');
        setLoading(false);
        return;
      }

      const rv_id = parseInt(id, 10);

      try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5001'; // Default value
        const fetchUrl = `${apiUrl}/api/rvs`;

        const response = await fetch(fetchUrl);

        if (!response.ok) {
          setError('Failed to fetch RV data');
          setLoading(false);
          return;
        }

        const data: RV[] = await response.json();

        // Find the RV with matching ID
        const foundRV = data.find((rvItem) => rvItem.id === rv_id);

        if (!foundRV) {
          setError('RV not found');
          setLoading(false);
          return;
        }

        setRV(foundRV);
      } catch (err) {
        console.error('Error fetching RV:', err);
        setError('An error occurred while fetching RV data');
      }
      setLoading(false);
    };

    fetchRV();
  }, [id]);

  if (loading) {
    return <div>Loading RV details...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!rv) {
    return <div className="error-message">RV not found</div>;
  }

  return (
    <div className="rv-details">
      <Card rv={rv} />
    </div>
  );
};

export default ShareCard;