// TermsAndConditions.tsx
import React from 'react';
import './style/TermsAndConditions.css';

const TermsAndConditions: React.FC = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>
      <p>Last updated: November 3rd, 2024</p>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to rvbig. These Terms and Conditions govern your use of our website and services. By accessing or using rvbig, you agree to be bound by these terms. If you do not agree with any part of these terms, you must not use our services.
        </p>
      </section>

      <section>
        <h2>Services Provided</h2>
        <p>
          rvbig offers a platform for users to find and compare recreational vehicles (RVs) and request quotes from various dealers. Our services include:
        </p>
        <ul>
          <li>Searching for RVs based on user preferences.</li>
          <li>Comparing different RV models and specifications.</li>
          <li>Requesting personalized quotes from authorized RV dealers.</li>
        </ul>
      </section>

      <section>
        <h2>User Responsibilities</h2>
        <p>
          When using rvbig, you agree to:
        </p>
        <ul>
          <li>Provide accurate and up-to-date information during registration and when requesting quotes.</li>
          <li>Maintain the confidentiality of your account credentials.</li>
          <li>Inform us immediately of any unauthorized use of your account.</li>
          <li>Use the platform responsibly and not engage in any activities that may harm us or other users.</li>
        </ul>
      </section>

      <section>
        <h2>Dealer Responsibilities</h2>
        <p>
          Authorized RV dealers on rvbig agree to:
        </p>
        <ul>
          <li>Provide accurate and detailed information about their RV listings.</li>
          <li>Respond promptly to user quote requests.</li>
          <li>Maintain professionalism and integrity in all interactions with users.</li>
          <li>Comply with all applicable laws and regulations governing RV sales and services.</li>
        </ul>
      </section>

      <section>
        <h2>Intellectual Property</h2>
        <p>
          All content, trademarks, logos, and intellectual property displayed on rvbig are the property of rvbig or their respective owners. You are prohibited from using, copying, or distributing any of our intellectual property without explicit permission.
        </p>
      </section>

      <section>
        <h2>Limitation of Liability</h2>
        <p>
          rvbig acts as an intermediary between users and RV dealers. While we strive to provide accurate information and facilitate smooth transactions, we are not liable for:
        </p>
        <ul>
          <li>Any direct, indirect, incidental, or consequential damages resulting from the use of our services.</li>
          <li>Any inaccuracies or omissions in recreational vehicle listings or user-submitted information.</li>
          <li>Any actions taken by dealers in response to quote requests.</li>
        </ul>
      </section>

      <section>
        <h2>Prohibited Activities</h2>
        <p>
          You agree not to:
        </p>
        <ul>
          <li>Scrape, crawl, or otherwise extract data from rvbig for any commercial or non-commercial purposes without our explicit written permission from rvbig.</li>
          <li>Use rvbig for any commercial purposes, including but not limited to selling, licensing, or distributing any content, services, or data obtained from rvbig, without prior approval from rvbig.</li>
          <li>Engage in any activity that interferes with or disrupts the functionality of rvbig.</li>
        </ul>
      </section>

      <section>
        <h2>Termination</h2>
        <p>
          We reserve the right to suspend or terminate your access to rvbig without prior notice if you violate these Terms and Conditions, engage in any prohibited activities, or due to our own discretion from rvbig.
        </p>
      </section>

      <section>
        <h2>Governing Law</h2>
        <p>
          These Terms and Conditions are governed by and construed in accordance with the laws of the United Arab Emirates. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in the United Arab Emirates.
        </p>
      </section>

      <section>
        <h2>Changes to These Terms</h2>
        <p>
          We may update our Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us using the 'Contact Us' button in the footer of the website:
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;