// Grid.tsx

import React, { useState, useEffect } from 'react';
import Card from './Card.tsx';
import WildCard from './WildCard.tsx';
import Filter from './Filter.tsx';
import Sort from './Sort.tsx';
import { RV } from './data/types.ts';
import './style/Grid.css';

const Grid: React.FC = () => {
  const [rvs, setRVs] = useState<RV[]>([]);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [minLength, setMinLength] = useState<number | null>(null);
  const [maxLength, setMaxLength] = useState<number | null>(null);
  const [minHeight, setMinHeight] = useState<number | null>(null);
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const [minWidth, setMinWidth] = useState<number | null>(null);
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const [minGVW, setMinGVW] = useState<number | null>(null);
  const [maxGVW, setMaxGVW] = useState<number | null>(null);
  const [minPayload, setMinPayload] = useState<number | null>(null);
  const [maxPayload, setMaxPayload] = useState<number | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [availableSeries, setAvailableSeries] = useState<string[]>([]);
  const [sortCriteria, setSortCriteria] = useState<
    | 'popular'
    | 'price-asc'
    | 'price-desc'
    | 'name-asc'
    | 'name-desc'
    | 'length-asc'
    | 'length-desc'
    | 'width-asc'
    | 'width-desc'
    | 'height-asc'
    | 'height-desc'
    | 'payload-asc'
    | 'payload-desc'
    | 'gvw-asc'
    | 'gvw-desc'
  >('popular');

  useEffect(() => {
    const fetchRVs = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const params = new URLSearchParams();

        if (minPrice !== null) params.append('minPrice', minPrice.toString());
        if (maxPrice !== null) params.append('maxPrice', maxPrice.toString());
        if (minLength !== null) params.append('minLength', minLength.toString());
        if (maxLength !== null) params.append('maxLength', maxLength.toString());
        if (minHeight !== null) params.append('minHeight', minHeight.toString());
        if (maxHeight !== null) params.append('maxHeight', maxHeight.toString());
        if (minWidth !== null) params.append('minWidth', minWidth.toString());
        if (maxWidth !== null) params.append('maxWidth', maxWidth.toString());
        if (minGVW !== null) params.append('minGVW', minGVW.toString());
        if (maxGVW !== null) params.append('maxGVW', maxGVW.toString());
        if (minPayload !== null) params.append('minPayload', minPayload.toString());
        if (maxPayload !== null) params.append('maxPayload', maxPayload.toString());
        if (selectedBrands.length > 0) params.append('brands', selectedBrands.join(','));
        if (selectedSeries.length > 0) params.append('series', selectedSeries.join(','));
        if (sortCriteria) {
          const [sortBy, sortOrder] = sortCriteria.split('-');
          params.append('sortBy', sortBy);
          params.append('sortOrder', sortOrder);
        }

        const response = await fetch(`${apiUrl}/api/rvs?${params.toString()}`);
        const data = await response.json();

        // Extract unique values for filters (brands and series)
        const brands = Array.from(new Set(data.map((rv: RV) => rv.brand)));
        const series = Array.from(new Set(data.map((rv: RV) => rv.series)));

        setAvailableBrands(brands);
        setAvailableSeries(series);

        setRVs(data);
      } catch (error) {
        console.error('Error fetching RV data:', error);
      }
    };
    fetchRVs();
  }, [
    minPrice,
    maxPrice,
    minLength,
    maxLength,
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    minGVW,
    maxGVW,
    minPayload,
    maxPayload,
    selectedBrands,
    selectedSeries,
    sortCriteria,
  ]);

  return (
    <div>
      <div className="controls-container">
        <div className="sort-container">
          <Sort sortCriteria={sortCriteria} setSortCriteria={setSortCriteria} />
        </div>
        <div className="filter-container">
          <Filter
            minPrice={minPrice}
            maxPrice={maxPrice}
            minLength={minLength}
            maxLength={maxLength}
            minHeight={minHeight}
            maxHeight={maxHeight}
            minWidth={minWidth}
            maxWidth={maxWidth}
            minGVW={minGVW}
            maxGVW={maxGVW}
            minPayload={minPayload}
            maxPayload={maxPayload}
            selectedBrands={selectedBrands}
            selectedSeries={selectedSeries}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            setMinLength={setMinLength}
            setMaxLength={setMaxLength}
            setMinHeight={setMinHeight}
            setMaxHeight={setMaxHeight}
            setMinWidth={setMinWidth}
            setMaxWidth={setMaxWidth}
            setMinGVW={setMinGVW}
            setMaxGVW={setMaxGVW}
            setMinPayload={setMinPayload}
            setMaxPayload={setMaxPayload}
            setSelectedBrands={setSelectedBrands}
            setSelectedSeries={setSelectedSeries}
            availableBrands={availableBrands}
            availableSeries={availableSeries}
          />
        </div>
        <h2 className="rv-count">{rvs.length} Filtered RVs</h2>
      </div>
      <div className="rv-grid">
        <WildCard />
        {rvs.map((rv) => (
          <Card key={rv.id} rv={rv} />
        ))}
      </div>
    </div>
  );
};

export default Grid;