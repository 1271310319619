// src/data/recents.ts

export interface RecentRV {
    id: number;
  title: string;
  image: string;
  description: string;
}

export const recents: RecentRV[] = [
  {
    id: 1,
    title: "Forest River: 2025 Cherokee Grey Wolf",
    image: "/images/recents/forest-river-grey-wolf.png",
    description: "Avg. saving: $16,971 off MSRP",
  },
  {
    id: 2,
    title: "Thor: 2025 Four Winds",
    image: "/images/recents/thor-four-winds.png",
    description: "Avg. saving: $39,525 off MSRP",
  },
  {
    id: 3,
    title: "Grand Design: 2025 Reflection",
    image: "/images/recents/grand-design-reflection.png",
    description: "Avg. saving $20,743 off MSRP",
  },
  {
    id: 4,
    title: "Jayco: 2025 Jay Flight SLX",
    image: "/images/recents/jayco-jayflight-slx.png",
    description: "Avg. saving: $11,752 off MSRP",
  },
  {
    id: 5,
    title: "Tiffin: 2025 Open Road Allegro",
    image: "/images/recents/tiffin-open-road-allegro.png",
    description: "Avg. saving: $70,667 off MSRP",
  },
  {
    id: 6,
    title: "Coachmen: 2025 Catalina Summit Series 8",
    image: "/images/recents/coachmen-catalina-series8.png",
    description: "Avg. saving: $9,343 off MSRP",
  },
  {
    id: 7,
    title: "Brinkley: 2025 Model Z",
    image: "/images/recents/brinkley-model-z.png",
    description: "Avg. saving: $14,283 off MSRP",
  },
];