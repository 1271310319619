// Modal.tsx

import React, { useEffect } from 'react';
import './style/Modal.css';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, children }) => {
  useEffect(() => {
    if (show) {
      // Prevent body from scrolling when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable body scrolling when modal is closed
      document.body.style.overflow = 'auto';
    }

    // Clean up when the component is unmounted or when 'show' changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        <button className="modal-close" onClick={onClose} aria-label="Close Modal">
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;